import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { JwtCustomPayload } from '../../../common/models/jwt-payload';
import { jwtDecode } from 'jwt-decode';
import { authConstants } from '../constants/auth.constants';


@Injectable({
    providedIn: 'root',
})
export class AdminAuthService {
    jwtHelper = new JwtHelperService();
    userModulesList!: [{ key: string; scopesEnabled: string[] }];

    public getToken(): string {
        return localStorage.getItem('token') ?? '';
    }

    public getSecretKey(): string {
        return localStorage.getItem('secretkey') ?? '';
    }

    public decodeJwtPayload(): JwtCustomPayload {
        const token: string = this.getToken();

        return jwtDecode<JwtCustomPayload>(token);
    }

    public getUserId(): string {
        const tokenPayload = this.decodeJwtPayload();

        return tokenPayload?.id;
    }

    public getUserName(): string {
        const tokenPayload = this.decodeJwtPayload();

        return `${tokenPayload.firstName} ${tokenPayload.lastName}`;
    }

    public getFirstName(): string {
        const tokenPayload = this.decodeJwtPayload();

        return `${tokenPayload.firstName}`;
    }

    public getLastName(): string {
        const tokenPayload = this.decodeJwtPayload();

        return `${tokenPayload.lastName}`;
    }

    public getEmail(): string {
        const tokenPayload = this.decodeJwtPayload();

        return `${tokenPayload.sub}`;
    }

    public getModulesAssigned(): string {
        const tokenPayload = this.decodeJwtPayload();

        return `${tokenPayload.mods}`;
    }

    public isModuleAssigned(module: string, scope?: string): boolean {
        if (this.hasRole(authConstants.ROLE_SUPER_ADMIN)) {
            return true;
        } else {
            const tokenPayload = this.decodeJwtPayload();

            if (tokenPayload?.modulesAssigned) {
                this.userModulesList = JSON.parse(tokenPayload?.modulesAssigned);
                if (this.userModulesList && this.userModulesList.length > 0) {
                    if (scope) {
                        return this.userModulesList.some((mod) => mod.key === module && mod.scopesEnabled.includes(scope));
                    } else {
                        return this.userModulesList.some((mod) => mod.key === module);
                    }
                }
            }
            return false;
        }
    }

    public getProjectsEnabled(): string {
        const tokenPayload = this.decodeJwtPayload();

        return `${tokenPayload.projectsEnabled}`;
    }

    public isProjectEnabled(project: string): boolean {
        return this.getProjectsEnabled().includes(project);
    }

    public isAuthenticated(): boolean {
        const token = this.getToken();

        return !this.jwtHelper.isTokenExpired(token);
    }

    public hasRole(expectedRole: string): boolean {
        if (this.isAuthenticated()) {
            const tokenPayload = this.decodeJwtPayload();
            return tokenPayload?.roles.some((role) => role.name === expectedRole);
        }
        return false;
    }

    public isLoggedIn(): boolean {
        if (this.isAuthenticated()) {
            const tokenPayload = this.decodeJwtPayload();

            return tokenPayload?.roles.filter((role) => role.name === authConstants.ROLE_USER).length > 0;
        }

        return false;
    }

    public isSuperAdmin(): boolean {
        if (this.isAuthenticated()) {
            const tokenPayload = this.decodeJwtPayload();

            return tokenPayload?.roles.filter((role) => role.name === authConstants.ROLE_SUPER_ADMIN).length > 0;
        }

        return false;
    }

    public isAdmin(): boolean {
        if (this.isAuthenticated()) {
            const tokenPayload = this.decodeJwtPayload();
            return tokenPayload?.roles.filter((role) => role.name === authConstants.ROLE_ADMIN).length > 0;
        }
        return false;
    }

    public isWorkDayUser(): boolean {
        if (this.isAuthenticated()) {
            const tokenPayload = this.decodeJwtPayload();
            return tokenPayload?.roles.filter((role) => role.name === authConstants.ROLE_WORKDAY_USER).length > 0;
        }
        return false;
    }

    public isAccountingPortal(): boolean {
        return sessionStorage.getItem('isAccountingPortal') === 'true';
    }

    public logout(): void {
        localStorage.clear();
        sessionStorage.clear();
    }

    public getAccountingToken(): string {
        return localStorage.getItem('accountingToken') ?? '';
    }
}
